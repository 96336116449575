import { useState, useEffect, useCallback } from 'react';
import { debounce } from '../lib/helpers';

export function useMedia() {
  const [mounted, setMounted] = useState(false);
  const [width, setWidth] = useState(undefined);

  useEffect(() => {
    setMounted(true);
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const handleResize = debounce(() => {
      setWidth(window.innerWidth);
    }, 200);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (mounted) {
    const result = {
      isMobile: width < 640,
      isTablet: width >= 640 && width < 1280,
      isDesktop: width >= 1280,
    };
    if (result.isMobile) {
      result.media = 'mobile';
    } else if (result.isTablet) {
      result.media = 'tablet';
    } else if (result.isDesktop) {
      result.media = 'desktop';
    }
    return result;
  } else {
    return {
      media: 'mobile',
      isMobile: true,
      isTablet: false,
      isDesktop: false,
    };
  }
}
