import { useContext, useEffect } from 'react';
import { MeasurementContext } from '../contexts';

export function useMeasurement() {
  const { value, setValue } = useContext(MeasurementContext);
  useEffect(() => {
    try {
      const storedValue = JSON.parse(
        window.localStorage.getItem('measurement')
      );
      if (storedValue) {
        setValue(storedValue);
      }
    } catch (e) {
      // nothing
    }
  }, [setValue]);

  useEffect(() => {
    if (value === null) {
      return;
    }
    window.localStorage.setItem('measurement', JSON.stringify(value));
  }, [value]);

  const validValues = ['g', 'oz'];

  return {
    measurement: validValues.includes(value) ? value : 'g',
    setMeasurement: setValue,
  };
}
