import { useState, useEffect } from 'react';
import { useMedia } from './useMedia';
import { navBarHeight } from '../components/const';

function buildThreshold(n = 100) {
  return Array(n)
    .fill()
    .map((_, index) => (index + 1) / n);
}

export function useIntersectionRatio(ref) {
  const [ratio, setRatio] = useState(undefined);
  const { media } = useMedia();
  useEffect(() => {
    if (!ref.current) {
      setRatio(undefined);
      return () => {};
    }
    const observer = new IntersectionObserver(
      entries => {
        if (ref.current) {
          setRatio(entries[0].intersectionRatio);
        }
      },
      {
        root: null,
        rootMargin: `-${navBarHeight[media]}px 0px 0px 0px`,
        threshold: buildThreshold(100),
      }
    );
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, media]);

  return { ratio };
}
