export const toRem = number => `${number / 16}rem`;
const arrayToMap = (keys, fn) =>
  keys.reduce((acc, key) => {
    acc[key] = fn(key); // eslint-disable-line no-param-reassign
    return acc;
  }, {});
const pixelToRem = (...pixels) => arrayToMap(pixels, toRem);

// https://theme-ui.com/theme-spec
export default {
  breakpoints: ['640px', '1280px'],
  colors: {
    black: '#2B2C2A',
    gray: '#575751',
    veryLightGray: '#7E7E7466',
    lightGray: '#7E7E74',
    background: '#D9DCD3',
    red: 'rgb(212, 103, 69)',
  },
  sizes: pixelToRem(5, 10, 20, 22, 24, 30, 48, 50, 100),
  space: pixelToRem(
    2,
    4,
    6,
    10,
    12,
    13,
    14,
    16,
    18,
    20,
    22,
    24,
    26,
    28,
    30,
    32,
    34,
    36,
    38,
    42,
    44,
    48,
    52,
    56,
    58,
    60,
    64,
    66,
    70,
    90,
    94,
    96,
    108,
    112,
    118,
    120,
    126,
    136,
    160,
    162,
    180
  ),
  letterSpacings: arrayToMap([10], num => `${num / 100}em`),
  fontSizes: pixelToRem(12, 13, 14, 16, 18, 20, 22, 24, 34),
  fonts: {
    logo: 'Open Sans',
    langLabel: 'Libre Baskerville',
    menuItem: 'Open Sans',
    breadcrumb1: 'Open Sans',
    footer: 'Open Sans',
    footerKoButton: 'Noto Sans KR',
    footerEnButton: 'Open Sans',
    measurementLabel: 'Open Sans',
    subscribeInputLabel: 'Open Sans',
    recommendation: 'Open Sans',
  },
  lineHeights: pixelToRem(18, 20, 22, 26, 28, 30, 38),
  text: {
    logo: {
      color: 'black',
      fontFamily: 'logo',
      fontSize: pixelToRem(16, 18),
      lineHeight: '1em',
      letterSpacing: 10,
    },
    langLabel: {
      fontSize: 14,
      fontFamily: 'langLabel',
      lineHeight: '1em',
    },
    measurementLabel: {
      fontSize: [14, 16, 16],
      fontFamily: 'measurementLabel',
      lineHeight: [18, 22, 22],
      color: 'lightGray',
    },
    postListTitle: {
      fontSize: [14, 16, 18],
      fontFamily: 'postListTitle',
      fontWeight: 'normal',
      lineHeight: ['1.7em', '1.625em', '1.77em'],
    },
    breadcrumbs: {
      fontFamily: 'breadcrumbs',
      fontSize: 14,
      lineHeight: '1em',
    },
    menuItem: {
      fontFamily: 'menuItem',
      fontSize: [20, 22, 16],
      lineHeight: ['1.8em', '1.8em', '1em'],
    },
    title: {
      lineHeight: ['1.75em', '1.7em', '1.5em'],
      fontSize: [24, 34, 40],
      fontFamily: 'title',
      fontWeight: 'medium',
    },
    subtitle: {
      lineHeight: '1em',
      fontSize: [14, 18, 20],
      fontFamily: 'subtitle',
      color: 'black',
    },
    postTitle: {
      fontSize: 24,
      fontFamily: 'postTitle',
      lineHeight: '1.75',
    },
    postAnotherVer: {
      fontSize: 14,
      lineHeight: '1em',
      fontFamily: 'postAnotherVer',
      color: 'black',
    },
    recipeTitle: {
      fontFamily: 'recipeTitle',
      fontSize: [18, 20, 22],
      lineHeight: '1em',
    },
    recipeBody: {
      fontFamily: 'recipeBody',
      fontSize: [14, 16],
      lineHeight: '1em',
    },
    postBody: {
      fontFamily: 'postBody',
      fontSize: 16,
      lineHeight: 26,
    },
    readThisArticle: {
      fontFamily: 'readThisArticle',
      fontSize: [13, 14, 16],
      lineHeight: ['1.69em', '1.71em', '1.75em'],
      color: 'lightGray',
    },
    imageCaption: {
      fontFamily: 'imageCaption',
      fontSize: [13, 14, 16],
      lineHeight: ['1.69em', '1.71em', '1.75em'],
      color: 'gray',
    },
  },
  buttons: {
    icon: {
      p: 0,
      width: 'initial',
      height: 'initial',
      cursor: 'pointer',
    },
    primary: {
      background: 'transparent',
      color: 'black',
      fontFamily: 'postBody',
      fontSize: 16,
      lineHeight: 26,
      py: 4,
      px: 10,
      m: 0,
      borderWidth: '1px',
      borderColor: 'gray',
      borderStyle: 'solid',
      borderRadius: 0,
      cursor: 'pointer',
    },
  },
  forms: {
    label: {
      width: 'initial',
    },
    input: {
      m: 0,
      px: 4,
      py: 0,
      border: 0,
      fontFamily: 'postBody',
      fontSize: 16,
      lineHeight: 26,
    },
  },
};

// https://github.com/tailwindcss/tailwindcss/blob/master/stubs/defaultConfig.stub.js
