export const tabletContainerMarginX = '1.875rem';
const tabletWidthExceptForMargin = `calc(100vw - ${tabletContainerMarginX}*2)`;
export const tabletLeftColumnSizeIncludingMargin = `calc(${tabletWidthExceptForMargin} * 150/580 + ${tabletContainerMarginX})`;
const tabletGridSize = `calc(${tabletWidthExceptForMargin} * 30/580)`;
const tabletGapSize = `calc(${tabletWidthExceptForMargin} * 20/580)`;
export const tabletGrid = (grid, gap) =>
  `calc(${tabletGridSize} * ${grid} + ${tabletGapSize} * ${gap})`;

const desktopGridSize = '54px';
const desktopGapSize = '36px';
export const desktopContainerMarginX = `calc((100vw - ${desktopGridSize} * 12 - ${desktopGapSize} * 11)/2)`;
export const desktopLeftColumnSizeIncludingMargin = `calc(${desktopGridSize} * 3 + ${desktopGapSize} * 3 + ${desktopContainerMarginX})`;
export const desktopGrid = (grid, gap) =>
  `calc(${desktopGridSize} * ${grid} + ${desktopGapSize} * ${gap})`;

export const containerMarginX = [
  toRem(22),
  tabletContainerMarginX,
  desktopContainerMarginX,
];

function toRem(number) {
  return `${number / 16}rem`;
}

export const navBarHeight = {
  mobile: 50,
  tablet: 54,
  desktop: 56,
};

export const navBarHeightsInArray = Object.values(navBarHeight).map(toRem);

export const getMenuItems = lang => [
  {
    title: 'Recipes',
    href: `/recipes/${lang}`,
  },
  {
    title: 'About',
    href: `/about/${lang}`,
  },
  {
    title: 'Instagram',
    href: 'https://instagram.com/merearchive',
    external: true,
  },
];
