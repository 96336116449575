import { useState, useEffect } from 'react';

export function useStickyState(defaultValue, key) {
  const [value, setValue] = useState(() => {
    if (typeof window === 'undefined') {
      return defaultValue;
    }

    const stickyValue = window.localStorage.getItem(key);
    try {
      return JSON.parse(stickyValue);
    } catch (e) {
      // do nothing;
    }
    return defaultValue;
  });

  useEffect(() => {
    const stickyValue = window.localStorage.getItem(key);
    try {
      setValue(JSON.parse(stickyValue));
      return;
    } catch (e) {
      // do nothing;
    }
    setValue(defaultValue);
  }, [defaultValue, key]);

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}
