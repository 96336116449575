/** @jsx jsx */
import { useState, useEffect } from 'react';
import { jsx, ThemeProvider } from 'theme-ui';
import { korean, english } from '../themes';
import { useLang } from '../hooks';
import { NavBarContext, MeasurementContext } from '../contexts';

import '../styles/layout.css';

const Layout = ({ lang, children }) => {
  const { setLang } = useLang();
  const [navBarContextValues, setNavBarContextValues] = useState(new Set());
  const [measurement, setMeasurement] = useState(null);
  useEffect(() => {
    setLang(lang);
  }, [lang, setLang]);

  return (
    <MeasurementContext.Provider
      value={{
        value: measurement,
        setValue: value => setMeasurement(value),
      }}
    >
      <NavBarContext.Provider
        value={{
          values: navBarContextValues,
          toggle: (value, toAdd) => {
            if (toAdd && navBarContextValues.has(value)) {
              return;
            }
            if (!toAdd && !navBarContextValues.has(value)) {
              return;
            }

            const newSet = new Set(navBarContextValues);
            if (toAdd) {
              newSet.add(value);
            } else {
              newSet.delete(value);
            }
            setNavBarContextValues(newSet);
          },
        }}
      >
        <ThemeProvider theme={lang === 'ko' ? korean : english}>
          {children}
        </ThemeProvider>
      </NavBarContext.Provider>
    </MeasurementContext.Provider>
  );
};

export default Layout;
