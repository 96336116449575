import { isFuture } from 'date-fns';

export * from './unit';
export * from './debounce';

export function cn(...args) {
  return args.filter(Boolean).join(' ');
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return [];
  return data.edges.map(edge => edge.node);
}

export function filterOutDocsWithoutSlugs({ slug }) {
  return (slug || {}).current;
}

export function filterOutDocsPublishedInTheFuture({ publishedAt }) {
  return !isFuture(publishedAt);
}

export function buildImageObj(source = { asset: {} }) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  };

  if (source.crop) imageObj.crop = source.crop;
  if (source.hotspot) imageObj.hotspot = source.hotspot;

  return imageObj;
}

export function getBlogUrl(isKorean, slug) {
  return `/post/${isKorean ? 'ko' : 'en'}/${slug.current || slug}/`;
}

export function toPlainText(blocks) {
  if (!blocks) {
    return '';
  }
  return blocks
    .map(block => {
      if (block._type !== 'block' || !block.children) {
        return '';
      }
      return block.children.map(child => child.text).join('');
    })
    .join('\n\n');
}

export function isKoreanBrowser() {
  if (typeof window === 'undefined') {
    return false;
  }
  const lang = (
    window.navigator.userLanguage || window.navigator.language
  ).toLowerCase();
  return lang === 'ko' || lang === 'ko-kr' || lang.startsWith('ko-');
}

export function hasKoreanSetting() {
  if (typeof window === 'undefined') {
    return false;
  }
  try {
    const lang = JSON.parse(window.localStorage.getItem('lang'));
    return lang === 'ko';
  } catch (err) {
    // nothing;
  }
  return false;
}

export function getDefaultLang() {
  return isKoreanBrowser() ? 'ko' : 'en';
}

export function extractPostNodes(posts, lang) {
  if (!posts) {
    return [];
  }

  const injectLang = node => ({
    ...node,
    lang: node.isKorean ? 'ko' : 'en',
  });
  const matchLanguage = node => (node.isKorean ? 'ko' : 'en') === lang;

  return mapEdgesToNodes(posts)
    .map(injectLang)
    .filter(filterOutDocsWithoutSlugs)
    .filter(filterOutDocsPublishedInTheFuture)
    .filter(matchLanguage);
}
