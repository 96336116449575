import merge from 'deepmerge';
import common, { toRem } from './common';

export default merge(common, {
  sizes: {
    emptyParaHeight1: toRem(28),
    emptyParaHeight2: toRem(30),
    emptyParaHeight3: toRem(38),
  },
  fonts: {
    postListTitle: 'Noto Serif KR',
    title: 'Noto Serif KR',
    subtitle: 'Libre Baskerville',
    postTitle: 'Noto Serif KR',
    postAnotherVer: 'Libre Baskerville',
    postBody: 'Noto Sans KR',
    recipeTitle: 'Noto Serif KR',
    recipeBody: 'Noto Sans KR',
    breadcrumb2: 'Noto Serif KR',
    subscribeTitle: 'Noto Sans KR',
    subscribeEmail: 'Open Sans',
    madeByTitle: 'Noto Sans KR',
    madeByLabel: 'Open Sans',
    madeByValue: 'Noto Sans KR',
    readThisArticle: 'Noto Sans KR',
    imageCaption: 'Noto Serif KR',
  },
  fontWeights: {
    medium: 500,
    normal: 400,
  },
  lineHeights: {
    postBody1: toRem(28),
    postBody2: toRem(30),
    postBody3: toRem(38),
  },
  fontSizes: {
    postBody1: toRem(16),
    postBody2: toRem(18),
    postBody3: toRem(20),
  },
});
