import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { imageUrlFor } from '../lib/image-url';
import { buildImageObj } from '../lib/helpers';
import { domain } from '../../config';

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
      title
      englishDescription
      koreanDescription
      keywords
      author {
        name
      }
      image {
        _key
        _type
        caption
        image {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
        }
      }
    }
  }
`;

function buildImageUrl(image) {
  return imageUrlFor(buildImageObj(image))
    .width(1200)
    .url();
}

function slice(text, length) {
  if (text.length <= length) {
    return text;
  } else {
    return `${text.slice(0, length)}...`;
  }
}

/* eslint-disable complexity */
/* eslint-disable no-warning-comments */
function SEO({
  description,
  lang = 'en',
  meta = [],
  keywords = undefined,
  title,
  image,
  path,
}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          slice(description || '', 120) ||
          (data.site &&
            (lang === 'ko'
              ? data.site.koreanDescription
              : data.site.englishDescription));
        const siteTitle = (data.site && data.site.title) || '';
        const siteAuthor =
          data.site && data.site.author && data.site.author.name;
        const metaImage =
          image && image.asset
            ? buildImageUrl(image)
            : buildImageUrl(data.site.image.image);
        const url = path ? `https://${domain}${path}` : undefined;
        const type = path && path.includes('/post/') ? 'article' : 'website';

        return (
          <Helmet
            htmlAttributes={{ lang }}
            title={title}
            titleTemplate={title === siteTitle ? '%s' : `%s | ${siteTitle}`}
            meta={[
              {
                name: 'description',
                content: metaDescription,
              },
              {
                property: 'og:title',
                content:
                  title === siteTitle ? title : `${title} | ${siteTitle}`,
              },
              {
                property: 'og:description',
                content: metaDescription,
              },
              {
                property: 'og:type',
                content: type,
              },
              {
                property: 'og:site_name',
                content: 'merearchive',
              },
              {
                property: 'og:image',
                content: metaImage,
              },
              url && {
                name: 'og:url',
                content: url,
              },
              {
                name: 'twitter:card',
                content: metaImage ? 'summary_large_image' : 'summary',
              },
              {
                name: 'twitter:creator',
                content: siteAuthor,
              },
              {
                name: 'twitter:title',
                content: title,
              },
              {
                name: 'twitter:description',
                content: metaDescription,
              },
              {
                property: 'twitter:image',
                content: metaImage,
              },
              {
                name: 'twitter:site',
                content: '@merearchive', // TODO: use twitter handle if possible
              },
              url && {
                name: 'twitter:url',
                content: url,
              },
              {
                name: 'twitter:label1',
                content: 'Written by',
              },
              {
                name: 'twitter:data1',
                content: 'Minji Jung',
              },
              // {
              //   property: 'og:updated_time',
              //   content: Number...
              // },
              // {
              //   property: 'article:published_time',
              //   content: Number...
              // },
              {
                property: 'article:tag',
                content: keywords || data.site.keywords || [],
              },
            ]
              .filter(Boolean)
              .concat({
                name: 'keywords',
                content: (keywords || data.site.keywords || []).join(', '),
              })
              .concat(meta)}
          />
        );
      }}
    />
  );
}
/* eslint-enable complexity */
/* eslint-enable no-warning-comments */

export default SEO;
