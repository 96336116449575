const unitMap = {
  gram: 'g',
  kilogram: 'kg',
  milliliter: 'mℓ',
  liter: 'ℓ',
  celsius: 'ºC',
};

function round(value, digit) {
  return Math.round(value * Math.pow(10, digit)) / Math.pow(10, digit);
}

export function convertUnit({ value, unit, isMetric }) {
  if (isMetric) {
    return {
      value,
      unit: unitMap[unit],
    };
  }

  const digit = 1;
  if (unit === 'gram' || unit === 'kilogram') {
    const gram = unit === 'kilogram' ? value * 1000 : value;
    if (gram < 454) {
      return {
        value: round(gram / 28.34952, digit),
        unit: 'oz',
      };
    } else {
      return {
        value: round(gram / 453.59237, digit),
        unit: 'lb',
      };
    }
  } else if (unit === 'milliliter' || unit === 'liter') {
    const milliliter = unit === 'liter' ? value * 1000 : value;
    if (milliliter >= 473) {
      const newValue = round(milliliter / 473.176473, digit);
      return {
        value: newValue,
        unit: newValue === 1 ? 'pint' : 'pints',
      };
    } else {
      return {
        value: round(milliliter / 29.573529562, digit),
        unit: 'fl oz',
      };
    }
  } else if (unit === 'celsius') {
    return {
      value: round((value * 9) / 5 + 32, 1),
      unit: 'ºF',
    };
  }
  throw new Error(
    JSON.stringify(
      { message: 'cannot convert!', value, unit, isMetric },
      null,
      2
    )
  );
}
